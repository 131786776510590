import { BLANK_VALUE } from "./constants";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js"; // Import UTC plugin
import timezone from "dayjs/plugin/timezone.js"; // Import UTC plugin
import { convertAmountRupeesPaise } from "./convertAmountToWords";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Kolkata");

export function replaceNull(jsonObject, replaceValue = BLANK_VALUE) {
  const replacedObject = (key, value) =>
    String(value) === "null" || String(value) === "undefined" || value === ""
      ? replaceValue
      : value;
  return JSON.parse(JSON.stringify(jsonObject, replacedObject));
}

export function replaceEmpty(jsonObject, replaceValue = "") {
  const replacedObject = (key, value) =>
    String(value) === BLANK_VALUE ? replaceValue : value;
  return JSON.parse(JSON.stringify(jsonObject, replacedObject));
}

export const setDataToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const isEmpty = (value) => {
  if (
    value === null ||
    value === undefined ||
    value === BLANK_VALUE ||
    value === ""
  ) {
    return true;
  }
  return false;
};

export const disableFutureDates = (current) => {
  return current && current.valueOf() > dayjs().tz("Asia/Kolkata").endOf("day");
};

export const formatDateToDayJS = (date) => {
  return date ? dayjs(date).tz("Asia/Kolkata") : null;
};

export const toPascalCase = (text, trimSpace = false) => text.split(' ').map((t) => t[0].toUpperCase() + t.slice(1).toLowerCase()).join(trimSpace ? '' : ' ')

export const convertAmountToWords = (number) => {
  return toPascalCase(convertAmountRupeesPaise(number));
}
