import { Button, Card, Space } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";
import AddClient from "./AddClient";
import { EditOutlined } from "@ant-design/icons";

function ClientTable() {
  const columns = [
    {
      title: "Client Name",
      dataIndex: "client_name",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "GSTIN",
      dataIndex: "gst",
    },
    {
      title: "State",
      dataIndex: "state",
    },

    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button size="small" onClick={() => editRecord(record)}>
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/client?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    const pagination =
      responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [responseData]);

  const handleAddClient = (isUpdated) => {
    const flag = visible ? false : true;
    setVisible(flag);
    if (isUpdated === true) {
      getRequest();
    }
  };

  const handleEditClient = (isUpdated) => {
    const flag = visibleEdit ? false : true;
    !flag && setSelectedRecord(null);
    setVisibleEdit(flag);
    if (isUpdated === true) {
      getRequest();
    }
  };

  const editRecord = (record) => {
    setVisibleEdit(true);
    setSelectedRecord(record);
  };

  const onPaginationChange = (page) => {
    getRequest(null, API_METHODS.GET, `/client?pageNumber=${page.current}`);
    setCurrentPage(page.current);
  };

  return (
    <Card title="Clients" extra={<Button type="primary" onClick={handleAddClient}>
      Add Client
    </Button>}>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        onPaginationChange={onPaginationChange}
        total={totalRecords}
        current={currentPage}
      />

      {visible && (
        <AddClient visible={visible} close={handleAddClient} edit={false} />
      )}
      {visibleEdit && (
        <AddClient
          visible={visibleEdit}
          selectedRecord={selectedRecord}
          close={handleEditClient}
          edit={true}
        />
      )}
    </Card>
  );
}

export default ClientTable;
