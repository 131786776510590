export const getLedgerHtml = () => {
    return `<table style="border-collapse: collapse; width: 100%; height: 39.6px; border: 1px solid black;" border="1">
    <colgroup>
        <col style="width: 99.8438%;">
    </colgroup>
    <tbody>
        <tr style="height: 39.6px;">
            <td style="border-color: black;  padding: 20px;"><strong><span style="font-size: 11px;">
                <div style="text-align: center;"><span style="font-size: 14px;">Ledger Report<br></span></div><br></span></strong>
                <table style="border-collapse: collapse; width: 100.063%; height: 21.2px;" border="1">
                    <colgroup>
                        <col style="width: 50%;">
                        <col style="width: 50%;">
                    </colgroup>
                    <tbody>
                        <tr style="height: 21.2px;">
                            <td style="border-color: black;"><strong><span style="font-size: 11px;">Client Name: {client_name}</span></strong></td>
                            <td style="text-align: right;"><strong><span style="font-size: 11px;">Start Date: {start_date}</span></strong></td>
                        </tr>
                        <tr>
                            <td style="border-color: black;"><strong><span style="font-size: 11px;">Opening Balance: {opening_balance}</span></strong></td>
                            <td style="text-align: right;"><strong><span style="font-size: 11px;">End Date: {end_date}</span></strong></td>
                        </tr>
                    </tbody>
                </table>
                <span style="font-size: 10px;"><br></span>
                <table style="border-collapse: collapse; width: 100.063%; height: 21.2px;" border="1">
                    <colgroup>
                        <col style="width: 15.0943%;">
                        <col style="width: 36.9462%;">
                        <col style="width: 16.3557%;">
                        <col style="width: 16.5094%;">
                        <col style="width: 14.9371%;">
                    </colgroup>
                    <tbody>
                        <tr style="height: 21.2px;">
                            <td style="text-align: center;"><strong><span style="font-size: 12px;">Date</span></strong></td>
                            <td style="text-align: center;"><strong><span style="font-size: 12px;">Particulars</span></strong></td>
                            <td style="text-align: center;"><strong><span style="font-size: 12px;">Debit</span></strong></td>
                            <td style="text-align: center;"><strong><span style="font-size: 12px;">Credit</span></strong></td>
                            <td style="text-align: center;"><strong><span style="font-size: 12px;">Balance</span></strong></td>
                        </tr>
                        {ledger_rows}
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>
<p>&nbsp;</p>`
}