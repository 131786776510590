import { ConfigProvider, Skeleton, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { BLANK_VALUE } from "../../../constants";
import { isEmpty } from "../../../generalFunctions";

const TableComponent = (props) => {
  const [columns, setColumns] = useState();
  const {
    tableData,
    loading,
    rowKey,
    rowSelection,
    clientSideRender,
    total,
    current,
  } = props;

  useEffect(() => {
    if (props.columns) {
      setColumns(props.columns);
    }
  }, [props.columns]);

  const clientSideSorting = (pagination, filters, sorter) => {
    if (sorter.field) {
      let columns = [...props.columns];
      let index =
        sorter.column === undefined
          ? sorter.dataIndex
          : sorter.column.dataIndex;
      let sortColumn = columns.find((c) => c.dataIndex === index);
      sortColumn = sorter;
      if (sortColumn.column && !sortColumn.column.useOwnSort) {
        sortColumn.column.sorter = (a, b) => {
          if (a[sortColumn.field] === undefined)
            a[sortColumn.field] = BLANK_VALUE;
          if (b[sortColumn.field] === undefined) {
            b[sortColumn.field] = BLANK_VALUE;
          }
          return a[sortColumn.field]
            .toString()
            .localeCompare(b[sortColumn.field].toString());
        };
      }
      setColumns(columns);
    }
  };

  const onPaginationChange = (page) => {
    props.onPaginationChange(page);
  };

  return !loading ? (
    <ConfigProvider
      theme={{
        token: {
          colorFillAlter: "#167373",
          colorTextHeading: "#ffffff",
          fontSize: 15,
          fontWeightStrong: 600,
        },
      }}
    >
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <Table
          rowKey={rowKey ? rowKey : "id"}
          columns={columns}
          dataSource={tableData}
          rowClassName={(record, index) =>
            index % 2 == 0 ? "row-even-color" : "row-odd-color"
          }
          pagination={
            !isEmpty(props.pagination)
              ? props.pagination
              : {
                  pageSize: 10,
                  showSizeChanger: false,
                  defaultCurrent: 1,
                  total: total,
                  current: current,
                }
          }
          onChange={clientSideRender ? clientSideSorting : onPaginationChange}
          scroll={{ y: window.innerHeight * 0.5 }}
          tableLayout="auto"
          rowSelection={rowSelection}
        />
      </Space>
    </ConfigProvider>
  ) : (
    <Skeleton />
  );
};

export default TableComponent;
