import { Button, Card, Space } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function ProformaInvoiceTable() {
  const navigate = useNavigate();

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Profroma Invoice Number",
      dataIndex: "proforma_inv_number",
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button size="small" onClick={() => editRecord(record)}>
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/proforma-invoice?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [tableData, setTableData] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    const pagination =
      responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [responseData]);

  const handleAddProformaInvoice = () => {
    navigate("/portal/proforma-invoice/add");
  };

  const editRecord = (record) => {
    navigate("/portal/proforma-invoice/add", {
      state: record,
    });
  };

  const onPaginationChange = (page) => {
    getRequest(
      null,
      API_METHODS.GET,
      `/proforma-invoice?pageNumber=${page.current}`
    );
    setCurrentPage(page.current);
  };

  return (
    <Card
      title="Proforma Invoices"
      extra={
        <Button type="primary" onClick={handleAddProformaInvoice}>
          Add Proforma Invoice
        </Button>
      }
    >
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        onPaginationChange={onPaginationChange}
        total={totalRecords}
        current={currentPage}
      />
    </Card>
  );
}

export default ProformaInvoiceTable;
