import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  message,
  Button,
  Card,
  DatePicker,
  Select,
} from "antd";
import { API_METHODS } from "../../constants";
import useApi from "../../hooks/useApi";
import { useLocation, useNavigate } from "react-router-dom";
import { disableFutureDates, formatDateToDayJS } from "../../generalFunctions";

function AddInvoicePayment() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [clients, setClients] = useState();
  const [inovicesDropdown, setInovicesDropdown] = useState();
  const [remainingBalance, setRemainingBalance] = useState();
  const [response, request] = useApi("/payment", null, API_METHODS.POST);
  const location = useLocation();
  const [editResponse, editRequest] = useApi(
    `/payment/${location.state && location.state.id}`,
    null,
    API_METHODS.PUT
  );

  const [responseDataClients, getClientsRequest] = useApi(
    `/client?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );

  const [getPaymentResponse, getPaymentRequest] = useApi(
    `/payment/${location.state && location.state.id}`,
    null,
    API_METHODS.GET
  );

  const [getRemainingBalanceResponse, getRemainingBalanceRequest] = useApi(
    `/invoice/remaining-balance`,
    null,
    API_METHODS.GET
  );

  const [getClientwiseInvoiceListResponse, getClientwiseInvoiceListRequest] =
    useApi(`/invoice/client-wise-not-paid/`, null, API_METHODS.GET);

  useEffect(() => {
    if (!location.state) {
      getClientsRequest();
    }
  }, []);

  useEffect(() => {
    if (location.state) {
      getPaymentRequest();
    }
  }, []);

  useEffect(() => {
    if (responseDataClients.data) {
      setClients(responseDataClients.data);
    }
  }, [responseDataClients]);

  useEffect(() => {
    if (getRemainingBalanceResponse.data) {
      setRemainingBalance(getRemainingBalanceResponse.data);
    }
  }, [getRemainingBalanceResponse]);

  useEffect(() => {
    if (getPaymentResponse.data) {
      getPaymentResponse.data.date = formatDateToDayJS(
        getPaymentResponse.data.date
      );
      // getClientwiseInvoiceListRequest(
      //   null,
      //   API_METHODS.GET,
      //   `/invoice/client-wise-not-paid/${getPaymentResponse.data.client_id}`
      // );
      form.setFieldsValue(getPaymentResponse.data);
    }
  }, [getPaymentResponse]);

  useEffect(() => {
    if (getClientwiseInvoiceListResponse.data) {
      setInovicesDropdown(getClientwiseInvoiceListResponse.data);
    }
  }, [getClientwiseInvoiceListResponse]);

  useEffect(() => {
    if (response.data) {
      navigate("/portal/payment");
      message.success("Payment added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      navigate("/portal/payment");
      message.success("Payment updated successfully.");
    }
  }, [editResponse]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        values.date = values.date.format("YYYY-MM-DD");
        if (location && location.state) {
          editRequest(values);
        } else {
          request(values, API_METHODS.POST);
        }
      })
      .catch((e) => { });
  };

  const handleCancel = () => {
    form.resetFields();
    navigate("/portal/payment");
  };

  const clientSelected = (value) => {
    form.setFieldValue("invoice_id", null);
    setRemainingBalance(null);
    getClientwiseInvoiceListRequest(
      null,
      API_METHODS.GET,
      `/invoice/client-wise-not-paid/${value}`
    );
  };

  const invoiceSelected = (value) => {
    // getInvoiceRequest(null, API_METHODS.GET, `/invoice/${value}`);
    setRemainingBalance(null);
    getRemainingBalanceRequest(
      null,
      API_METHODS.GET,
      `/invoice/remaining-balance/${value}`
    );
  };

  return (
    <Card title="Payment">
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="client_id" label="Client">
              <Select
                placeholder="Select Client"
                onSelect={clientSelected}
                disabled={location.state}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {clients?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.client_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="invoice_id" label="Invoice">
              <Select
                placeholder="Select  Invoice"
                onSelect={invoiceSelected}
                disabled={location.state}
              >
                {inovicesDropdown?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.number}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {remainingBalance && (
            <Col lg={12} md={24} sm={24} xs={24}>
              <Form.Item label="Remaining Balance">
                <Input disabled value={remainingBalance} />
              </Form.Item>
            </Col>
          )}
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="date" label="Payment Date">
              <DatePicker
                placeholder="Select Date"
                style={{ width: "100%" }}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="amount" label="Amount">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ float: "right" }}>
          <Col span={24}>
            <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={handleOk}
            >
              {location.state ? "Update" : "Save"}
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default AddInvoicePayment;
