import { Button, Card, Space } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function QuotationTable() {
  const navigate = useNavigate();

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Quotation Number",
      dataIndex: "quotation_number",
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button size="small" onClick={() => editRecord(record)}>
            <EditOutlined />
          </Button>
          <Button size="small" onClick={() => editRecord(record, true)}>
            <EyeOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/quotation?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [tableData, setTableData] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    const pagination =
      responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [responseData]);

  const handleAddQuotation = () => {
    navigate("/portal/quotation/add");
  };

  const editRecord = (record, isViewOnly = false) => {
    navigate("/portal/quotation/add", {
      state: {
        record,
        isViewOnly
      }
    });
  };

  const onPaginationChange = (page) => {
    getRequest(null, API_METHODS.GET, `/quotation?pageNumber=${page.current}`);
    setCurrentPage(page.current);
  };

  return (
    <Card
      title="Quotations"
      extra={
        <Button type="primary" onClick={handleAddQuotation}>
          Add Quotation
        </Button>
      }
    >
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        onPaginationChange={onPaginationChange}
        total={totalRecords}
        current={currentPage}
      />
    </Card>
  );
}

export default QuotationTable;
