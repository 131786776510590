import React, { useState } from "react";
import { ConfigProvider, Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  SettingOutlined,
  FileExclamationOutlined,
  FileDoneOutlined,
  FileAddOutlined,
  WalletOutlined,
  HomeOutlined,
  BarChartOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

// import logo from "../assets/logo-text.png";

const { Header, Sider, Content } = Layout;

function MainLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logoutClicked = () => {
    //TODO: api call for token cancellation
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    localStorage.clear();
    navigate("/");
  };

  const items = [
    {
      label: "Dashboard",
      path: "dashboard",
      key: "dashboard",
      icon: <HomeOutlined />,
    },
    {
      label: "Quotation",
      path: "quotation",
      key: "quotation",
      icon: <FileAddOutlined />,
    },
    {
      label: "Proforma Invoice",
      path: "proforma-invoice",
      key: "proforma-invoice",
      icon: <FileExclamationOutlined />,
    },
    {
      label: "Invoice",
      path: "invoice",
      key: "invoice",
      icon: <FileDoneOutlined />,
    },
    {
      label: "Invoice Payment",
      path: "payment",
      key: "payment",
      icon: <WalletOutlined />,
    },
    {
      label: "Settings",
      key: "Settings",
      icon: <SettingOutlined />,
      children: [
        {
          label: "Clients",
          path: "settings/clients",
          key: "clients",
          icons: <RightOutlined />
        },
      ],
    },
    {
      label: "Reports",
      path: "report",
      key: "report",
      icon: <BarChartOutlined />,
    },
    {
      label: "Log Out",
      key: "logout",
      icon: <LogoutOutlined />,
      onClick: logoutClicked,
    },
  ];

  let href = window.location.href?.split("/");
  href = href.slice(-1);

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
        {/* <div className="logo">
          <img
            style={{ height: 50, width: "100%" }}
            src={logo}
            alt="GPAT Academy"
          />
        </div> */}
        <ConfigProvider
          theme={{
            token: {
              fontSize: 14,
              itemBorderRadius: 16,
            },
          }}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={href}
            selectedKeys={href}
            items={items.map((l1_item, index) => {
              return {
                ...l1_item,
                label: l1_item?.path ? (
                  <Link to={l1_item?.path}>{l1_item?.label}</Link>
                ) : (
                  <>{l1_item?.label}</>
                ),
                children: l1_item?.children?.map((l2_item, l2_index) => {
                  return {
                    ...l2_item,
                    label: l2_item?.path ? (
                      <Link to={l2_item?.path}>{l2_item?.label}</Link>
                    ) : (
                      <>{l2_item?.label}</>
                    ),
                  };
                }),
              };
            })}
          ></Menu>
        </ConfigProvider>
      </Sider>
      <Layout
        className="site-layout"
        style={{
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <Header style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: toggle,
            }
          )}
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 5,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
