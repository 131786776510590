import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../constants";
import useApi from "../hooks/useApi";
import { Card } from "antd";
import { isEmpty } from "../generalFunctions";

function Dashboard() {
  const isMobile = window.innerWidth < 700 ? true : false;
  const [outstandingBalanceResponse, getOutstandingBalanceRequest] = useApi(
    `/report/outstanding-balance`,
    null,
    API_METHODS.GET
  );

  const [outstandingBalance, setOutstandingBalance] = useState([]);

  useEffect(() => {
    getOutstandingBalanceRequest();
  }, []);

  useEffect(() => {
    const data =
      outstandingBalanceResponse.data !== BLANK_VALUE
        ? outstandingBalanceResponse.data
        : [];
    setOutstandingBalance(data);
  }, [outstandingBalanceResponse]);

  const gridStyle = {
    width: isMobile ? '100%' : '25%',
    textAlign: 'center',
  };

  return <Card title="Outstanding Balance">
    {outstandingBalance?.map(element => <Card.Grid style={gridStyle}>
      <h2>{element.client_name}</h2>
      <h1 style={{ color: "red" }}>{element.balance}</h1>
    </Card.Grid>)}
  </Card>;
}

export default Dashboard;
