export const API_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export const DEFAULT_DATE_FORMAT = "DD-MM-YYYY";
export const BLANK_VALUE = "__";
export const EMPTY_VALUE = "";
