import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  message,
  Divider,
  Button,
  InputNumber,
  Card,
  DatePicker,
  Select,
  Space,
} from "antd";
import { API_METHODS } from "../../constants";
import useApi from "../../hooks/useApi";
import { MinusCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { disableFutureDates, formatDateToDayJS } from "../../generalFunctions";
import ProformaInvoicePrint from "./ProformaInvoicePrint";
import { useReactToPrint } from "react-to-print";

function AddProformaInvoice() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [clients, setClients] = useState();
  const [quotationDropdown, setQuotationDropdown] = useState();
  const [quotationDetails, setQuotationDetails] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [isIgstInvoice, setIsIgstInvoice] = useState(false);
  const { TextArea } = Input;
  const [response, request] = useApi(
    "/proforma-invoice",
    null,
    API_METHODS.POST
  );
  const location = useLocation();
  const [editResponse, editRequest] = useApi(
    `/proforma-invoice/${location.state && location.state.id}`,
    null,
    API_METHODS.PUT
  );
  const [responseDataClients, getClientsRequest] = useApi(
    `/client?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );

  const [getProformaInvoiceResponse, getProformaInvoiceRequest] = useApi(
    `/proforma-invoice/${location.state && location.state.id}`,
    null,
    API_METHODS.GET
  );

  const [getQuotationResponse, getQuotationRequest] = useApi(
    `/quotation/`,
    null,
    API_METHODS.GET
  );

  const [
    getClientwiseQuotationListResponse,
    getClientwiseQuotationListRequest,
  ] = useApi(`/quotation/client-wise/`, null, API_METHODS.GET);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Proforma Invoice',
    onAfterPrint: () => console.log('Printed PDF successfully!'),
  });

  useEffect(() => {
    if (!location.state) {
      getClientsRequest();
    }
  }, []);

  useEffect(() => {
    if (location.state) {
      getProformaInvoiceRequest();
    }
  }, []);

  useEffect(() => {
    if (responseDataClients.data) {
      setClients(responseDataClients.data);
    }
  }, [responseDataClients]);

  useEffect(() => {
    if (getQuotationResponse.data) {
      let { data } = getQuotationResponse;
      const total = data.descriptions.splice(data.descriptions.length - 1, 1);
      data.descriptions.forEach(item => {
        item.quantity = item.unit;
        item.amount = item.price;
      })
      form.setFieldValue("descriptions", data.descriptions);
      setQuotationDetails(data);
    }
  }, [getQuotationResponse]);

  useEffect(() => {
    if (getProformaInvoiceResponse.data) {
      let { data } = getProformaInvoiceResponse;
      const isIgstInvoice = data.description.find(element => element.description === "IGST");
      setIsIgstInvoice(isIgstInvoice);
      let total;
      if (isIgstInvoice) {
        total = data.description.splice(data.description.length - 2, 2);
      } else {
        total = data.description.splice(data.description.length - 3, 3);
      }
      data.total = total;

      const totalSAC = data.sac_table.splice(data.sac_table.length - 1, 1);
      data.totalSAC = totalSAC;
      data.date = formatDateToDayJS(
        data.date
      );
      setInvoiceDetails(data);
      form.setFieldValue("descriptions", data.description);
      form.setFieldsValue(data);
    }
  }, [getProformaInvoiceResponse]);

  useEffect(() => {
    if (getClientwiseQuotationListResponse.data) {
      setQuotationDropdown(getClientwiseQuotationListResponse.data);
    }
  }, [getClientwiseQuotationListResponse]);

  useEffect(() => {
    if (response.data) {
      navigate("/portal/proforma-invoice");
      message.success("Proforma Invoice added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      navigate("/portal/proforma-invoice");
      message.success("Proforma Invoice updated successfully.");
    }
  }, [editResponse]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        values.date = values.date.format("YYYY-MM-DD");
        values.descriptions.forEach((item, index) => {
          item.sr_number = index + 1;
        });
        values.terms?.forEach((item, index) => {
          item.id = index + 1;
        });
        if (location && location.state) {
          editRequest(values);
        } else {
          request(values, API_METHODS.POST);
        }
      })
      .catch((e) => { });
  };

  const handleCancel = () => {
    form.resetFields();
    navigate("/portal/proforma-invoice");
  };

  const clientSelected = (value) => {
    form.setFieldValue("quotation_id", null);
    getClientwiseQuotationListRequest(
      null,
      API_METHODS.GET,
      `/quotation/client-wise/${value}`
    );
  };

  const quotationSelected = (value) => {
    getQuotationRequest(null, API_METHODS.GET, `/quotation/${value}`);
  };

  const calculateDescriptionTotal = (name) => {
    const description = form.getFieldValue("descriptions");
    const rate = description[name].rate;
    const quantity = description[name].quantity;
    if (rate && quantity) {
      description[name].amount = rate * quantity;
      form.setFieldValue("descriptions", description);
    }
  }

  const printInvoice = () => {
    handlePrint();
  }

  return (
    <Card title={location.state ? "Update Proforma Invoice" : "New Proforma Invoice"}
      extra={
        invoiceDetails && <Button type="primary" onClick={() => printInvoice()}>
          <PrinterOutlined /> Print Proforma Invoice
        </Button>
      }>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          mode_of_payment: "Online",
          cgst: 9,
          sgst: 9
        }}
      >
        <Row gutter={16}>
          <Divider>
            <b>Client & Quotation Details</b>
          </Divider>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="client_id" label="Client"
              rules={[{
                required: true
              }]}>
              <Select placeholder="Select Client" onSelect={clientSelected} disabled={location.state}
                  showSearch
                  filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                  }>
                {clients?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.client_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="quotation_id" label="Quotation"
              rules={[{
                required: true
              }]}>
              <Select
                placeholder="Select Quotation"
                onSelect={quotationSelected}
                disabled={location.state}
              >
                {quotationDropdown?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.number}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="date" label="Proforma Invoice Date"
              rules={[{
                required: true
              }]}>
              <DatePicker
                placeholder="Select Date"
                style={{ width: "100%" }}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="mode_of_payment" label="Mode/Terms of Payment"
              rules={[{
                required: true
              }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="cgst" label="CGST (%)">
              <InputNumber min={0} onChange={() => { form.setFieldValue({ igst: undefined }); }} />
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="sgst" label="SGST (%)">
              <InputNumber min={0} onChange={() => { form.setFieldValue({ igst: undefined }); }} />
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="igst" label="IGST (%)">
              <InputNumber min={0} onChange={() => { form.setFieldsValue({ cgst: undefined, sgst: undefined }); }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Divider>
            <b>Terms</b>
          </Divider>
          <Col span={24}>
            <Form.List name="terms">
              {(fields, { add, remove }) => (
                <Row>
                  {fields.map(({ key, name, ...restField }) => (
                    <Col span={24}>
                      <Row gutter={8}>
                        <Col lg={11} md={22} sm={22} xs={22}>
                          <Form.Item
                            {...restField}
                            name={[name, "label"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter label.",
                              },
                            ]}
                          >
                            <Input placeholder="Label" />
                          </Form.Item>
                        </Col>
                        <Col lg={11} md={22} sm={22} xs={22}>
                          <Form.Item
                            {...restField}
                            name={[name, "text"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter text.",
                              },
                            ]}
                          >
                            <Input placeholder="Value" />
                          </Form.Item>
                        </Col>
                        <Col lg={2} md={22} sm={22} xs={22}>
                          <Button onClick={() => remove(name)} >Remove</Button>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                  <Form.Item>
                    <Button style={{ marginTop: 20 }} type="primary" onClick={() => add()} block icon={"+"}>
                      Add Terms
                    </Button>
                  </Form.Item>
                </Row>
              )}
            </Form.List>
          </Col>
        </Row>
        {(quotationDetails || invoiceDetails) && <Row gutter={16}>
          <Divider><b>Description</b></Divider>
          <Form.List name="descriptions">
            {(fields, { add, remove }) => (
              <Col span={24}>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Form.Item
                      {...restField}
                      label="Description"
                      name={[name, 'description']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter description.',
                        },
                      ]}
                    >
                      <TextArea placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Rate"
                      name={[name, 'rate']}
                    >
                      <InputNumber min={0} placeholder="Rate" onChange={() => calculateDescriptionTotal(name)} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Quantity"
                      name={[name, 'quantity']}
                    >
                      <InputNumber min={0} placeholder="Quantity" onChange={() => calculateDescriptionTotal(name)} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Amount"
                      name={[name, 'amount']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter amount.',
                        },
                      ]}
                    >
                      <InputNumber min={0} placeholder="Amount" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Per"
                      name={[name, 'per']}
                    >
                      <Input placeholder="per" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="SAC"
                      name={[name, 'sac']}
                      rules={[
                        {
                          required: true,
                          message: 'SAC is required.',
                        },
                      ]}
                    >
                      <InputNumber min={0} placeholder="SAC" />
                    </Form.Item>
                    <Button style={{ marginBottom: 20 }} onClick={() => remove(name)}><MinusCircleOutlined />Remove Description</Button>
                  </>
                ))}
                <Form.Item>
                  <Button type="primary" onClick={() => add()} block icon={"+"}>
                    Add Description
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Form.List>
        </Row>}
        <Row style={{ float: "right" }}>
          <Col span={24}>
            <Button type="primary" style={{ marginRight: 10 }} onClick={handleOk}>{location.state ? "Update" : "Save"}</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>
      </Form>
      {invoiceDetails && <div style={{ visibility: "hidden", display: "none" }} ><ProformaInvoicePrint componentRef={componentRef} invoiceDetails={invoiceDetails} isIgstInvoice={isIgstInvoice} /></div>}
    </Card>
  );
}

export default AddProformaInvoice;
