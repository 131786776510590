import "./App.css";
import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import Authentication from "./components/Authentication";
import MainLayout from "./components/MainLayout";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import ClientTable from "./components/Clients/ClientTable";
import QuotationTable from "./components/Quotation/QuotationTable";
import ProformaInvoiceTable from "./components/Proforma Invoice/ProformaInvoiceTable";
import InvoiceTable from "./components/Invoice/InvoiceTable";
import PageNotFound from "./components/PageNotFound";
import AddQuotation from "./components/Quotation/AddQuotation";
import AddProformaInvoice from "./components/Proforma Invoice/AddProformaInvoice";
import AddInvoice from "./components/Invoice/AddInvoice";
import InvoicePaymentTable from "./components/Invoice Payment/InvoicePaymentTable";
import AddInvoicePayment from "./components/Invoice Payment/AddInvoicePayment";
import Reports from "./components/Reports";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Login />}></Route>
      <Route element={<Authentication />}>
        <Route path="portal" element={<MainLayout />}>
          <Route path="/portal/dashboard" element={<Dashboard />} />
          <Route path="/portal/settings/clients" element={<ClientTable />} />
          <Route path="/portal/quotation" element={<QuotationTable />} />
          <Route path="/portal/quotation/add" element={<AddQuotation />} />
          <Route
            path="/portal/proforma-invoice"
            element={<ProformaInvoiceTable />}
          />
          <Route
            path="/portal/proforma-invoice/add"
            element={<AddProformaInvoice />}
          />
          <Route path="/portal/invoice" element={<InvoiceTable />} />
          <Route path="/portal/invoice/add" element={<AddInvoice />} />
          <Route path="/portal/payment" element={<InvoicePaymentTable />} />
          <Route path="/portal/payment/add" element={<AddInvoicePayment />} />
          <Route path="/portal/report" element={<Reports />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </>
  )
);

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerBg: "rgb(20, 168, 0)",
            colorTextHeading: "rgb(255, 255, 255)",
          },
        },
        token: {
          algorithm: "compactAlgorithm",
          // Seed Token
          colorPrimary: "#1677ff",
          borderRadius: 11,
          fontFamily: "Arial",
          colorTextBase: "#455560",
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
