import React, { useEffect, useRef, useState } from "react";
import { Form, Col, Row, Button, Card, DatePicker, Select } from "antd";
import { API_METHODS } from "../constants";
import useApi from "../hooks/useApi";
import { disableFutureDates } from "../generalFunctions";
import { useNavigate } from "react-router-dom";
import LedgerPrint from "./Reports/Ledger/LedgerPrint";
import { useReactToPrint } from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";

function Reports() {
  const [form] = Form.useForm();
  const reportType = [
    {
      id: 1,
      type: "Ledger",
    },
  ];
  const [clients, setClients] = useState();
  const [responseDataClients, getClientsRequest] = useApi(
    `/client?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [ledgerResponse, getLedgerRequest] = useApi(
    `/report/ledger`,
    null,
    API_METHODS.GET
  );
  const [ledgerData, setLedgerData] = useState();
  const navigate = useNavigate();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: ledgerData ? `Ledger_Report ${ledgerData.client_name} - ${ledgerData.start_date} to ${ledgerData.end_date}` : "Ledger Report",
    onAfterPrint: () => console.log('Printed PDF successfully!'),
  });

  useEffect(() => {
    getClientsRequest();
  }, []);

  useEffect(() => {
    if (responseDataClients.data) {
      setClients(responseDataClients.data);
    }
  }, [responseDataClients]);

  useEffect(() => {
    if (ledgerResponse.data) {
      setLedgerData(ledgerResponse.data);
    }
  }, [ledgerResponse]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        values.start_date = values.start_date.format("YYYY-MM-DD");
        values.end_date = values.end_date.format("YYYY-MM-DD");
        if (values.report_type == 1) {
          getLedgerRequest(
            null,
            API_METHODS.GET,
            `/report/ledger/${values.client_id}?start_date=${values.start_date}&?end_date=${values.end_date}`
          );
        }
      })
      .catch((e) => { });
  };

  const handleCancel = () => {
    form.resetFields();
    navigate("/portal/dashboard");
  };

  const printReport = () => {
    handlePrint();
  }

  return (
    <Card title="Reports"
      extra={
        ledgerData &&
        <Button type="primary" onClick={() => printReport()}>
          <PrinterOutlined /> Print Report
        </Button>
      }>
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="client_id" label="Client">
              <Select placeholder="Select Client"     
                showSearch
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }>
                {clients?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.client_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="report_type" label="Report Type">
              <Select placeholder="Select Client">
                {reportType?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="start_date" label="Start Date">
              <DatePicker
                placeholder="Select Date"
                style={{ width: "100%" }}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            <Form.Item name="end_date" label="End Date">
              <DatePicker
                placeholder="Select Date"
                style={{ width: "100%" }}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ float: "right" }}>
          <Col span={24}>
            <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={handleOk}
            >
              Submit
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Col>
        </Row>
      </Form>
      {ledgerData && <div style={{ visibility: "hidden", display: "none" }} ><LedgerPrint componentRef={componentRef} ledgerDetails={ledgerData} /></div>}
    </Card>
  );
}

export default Reports;
