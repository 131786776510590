import { Button, Card, Form, Input, message, Modal, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import useApi from "../../hooks/useApi";
import TableComponent from "../common/table/TableComponent";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function InvoiceTable() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [activeTab, setActiveTab] = useState(1);
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button size="small" onClick={() => editRecord(record)}>
            <EditOutlined />
          </Button>
          {activeTab == 1 && (
            <Button size="small" onClick={() => deleteRecord(record)}>
              <DeleteOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const [responseData, getRequest] = useApi(
    `/invoice?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [deletedInvoicesResponse, deletedInvoicesRequest] = useApi(
    `/invoice/deleted?pageNumber=${1}`,
    null,
    API_METHODS.GET
  );
  const [tableData, setTableData] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeletePopup, setshowDeletePopup] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [form] = Form.useForm();
  const [deleteInvoiceResponse, deleteInvoiceRequest] = useApi(
    `/invoice`,
    null,
    API_METHODS.DELETE
  );

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    const data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    const pagination =
      responseData.pagination !== BLANK_VALUE ? responseData.pagination : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [responseData]);

  useEffect(() => {
    const data =
      deletedInvoicesResponse.data !== BLANK_VALUE
        ? deletedInvoicesResponse.data
        : [];
    const pagination =
      deletedInvoicesResponse.pagination !== BLANK_VALUE
        ? deletedInvoicesResponse.pagination
        : {};
    setTableData(data);
    setTotalRecords(pagination ? pagination.count : 0);
  }, [deletedInvoicesResponse]);

  useEffect(() => {
    setSelectedRecord(null);
    setshowDeletePopup(false);
    form.resetFields();
    if (deleteInvoiceResponse.data) {
      messageApi.open({
        type: "success",
        content: "Invoice Deleted Successfully.",
      });
    } else if (deleteInvoiceResponse.error) {
      messageApi.open({
        type: "error",
        content: deleteInvoiceResponse.error,
      });
    }
    getRequest();
  }, [deleteInvoiceResponse]);

  const handleAddInvoice = () => {
    navigate("/portal/invoice/add");
  };

  const editRecord = (record) => {
    navigate("/portal/invoice/add", {
      state: {
        record: record,
        is_dummy_invoice: activeTab == 2 ? true : false,
      },
    });
  };

  const deleteRecord = (record) => {
    setshowDeletePopup(true);
    setSelectedRecord(record);
  };

  const handleCancel = () => {
    setshowDeletePopup(false);
    setSelectedRecord(null);
    form.resetFields();
  };

  const onTabChange = (key) => {
    setActiveTab(key);
    setCurrentPage(1);
    if (key == 1) {
      getRequest();
    } else if (key == 2) {
      deletedInvoicesRequest();
    }
  };

  const handleDelete = () => {
    form
      .validateFields()
      .then((values) => {
        deleteInvoiceRequest(
          values,
          API_METHODS.DELETE,
          `/invoice/${selectedRecord.id}`
        );
      })
      .catch((e) => {});
  };

  const onPaginationChange = (page) => {
    setCurrentPage(page.current);
    if (activeTab == 2) {
      deletedInvoicesRequest(
        null,
        API_METHODS.GET,
        `/invoice/deleted?pageNumber=${page.current}`
      );
    } else {
      getRequest(null, API_METHODS.GET, `/invoice?pageNumber=${page.current}`);
    }
  };

  const items = [
    {
      key: 1,
      label: "Invoices",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
    {
      key: 2,
      label: "Deleted Invoices",
      children: (
        <TableComponent
          columns={columns}
          tableData={tableData}
          loading={responseData.isLoading}
          onPaginationChange={onPaginationChange}
          total={totalRecords}
          current={currentPage}
        />
      ),
    },
  ];

  return (
    <Card
      title="Invoices"
      extra={
        <Button type="primary" onClick={handleAddInvoice}>
          Add Invoice
        </Button>
      }
    >
      {contextHolder}

      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        items={items}
        onChange={onTabChange}
      />
      <Modal
        title="Delete Invoice"
        open={showDeletePopup}
        onOk={handleDelete}
        onCancel={handleCancel}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
}

export default InvoiceTable;
