import React, { useEffect } from "react";
import { Modal, Form, Input, Col, Row, message, InputNumber } from "antd";
import { API_METHODS } from "../../constants";
import useApi from "../../hooks/useApi";

function AddClient(props) {
  const [form] = Form.useForm();
  const { visible, close, selectedRecord } = props;

  const [response, request] = useApi("/client", null, API_METHODS.POST);
  const [editResponse, editRequest] = useApi(
    `/client/${selectedRecord && selectedRecord.id}`,
    null,
    API_METHODS.PUT
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Client added successfully.");
    }
  }, [response]);

  useEffect(() => {
    if (editResponse.data) {
      close(true);
      message.success("Client updated successfully.");
    }
  }, [editResponse]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedRecord) {
          editRequest(values);
        } else {
          request(values, API_METHODS.POST);
        }
      })
      .catch((e) => { });
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title={selectedRecord ? "Update Customer" : "Add Customer"}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col lg={8} md={24} sm={24} xs={24}>
            <Form.Item
              name="client_name"
              label="Client Name"
              rules={[
                { required: true, message: "Client Name is required." },
                {
                  max: 200,
                  message: "Client Name should be maximum of 200 characters.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24}>
            <Form.Item name="address" label="Address">
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24}>
            <Form.Item name="gst" label="GSTIN">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24}>
            <Form.Item name="state" label="State">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24}>
            <Form.Item name="code" label="Code">
              <InputNumber style={{ width: 100 }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddClient;
